import { Event } from "../events/EventDispatcher";
import { SceneEvent } from "../SceneEvent";
import { DataStorage } from '../DataStorage';
import { gsap, Power1 } from "gsap";

export class HeaderView
{
	private element: HTMLElement;
	
	private menu: HTMLElement;
	private opener: HTMLElement;
	private logo: HTMLElement;

	private l0: HTMLElement;
	private l1: HTMLElement;

	private isOpen: boolean = false;

	private addBindThis: Function;
	private removeBindThis: Function;

	constructor()
	{
		this.element = document.querySelector("#header");
		
		this.menu = this.element.querySelector("._menu");
		this.opener = this.element.querySelector("._opener");
		this.logo = this.element.querySelector("._logo");

		this.l0 = this.opener.querySelector("._l0");
		this.l1 = this.opener.querySelector("._l1");

		this.opener.addEventListener('click', this.onClick.bind(this));
		
		this.element.querySelector('._backToTop').addEventListener('click', (event) =>
		{
			location.href = DataStorage.INDEX_URL;
		});
		
		this.element.querySelector('._sendYourhistory').addEventListener('click', (event) =>
		{
			SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.REMOVE_TOP));
			SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.ADD_SEND_YOURHISTORY));

			this.close();
		});

		this.addBindThis = this.add.bind(this);
		SceneEvent.getInstance().addEventListener(SceneEvent.ADD_HEADER, this.addBindThis);

		this.removeBindThis = this.remove.bind(this);
		SceneEvent.getInstance().addEventListener(SceneEvent.REMOVE_HEADER, this.removeBindThis);
		
	}
	public add(): void
	{
		gsap.to(this.element, 0.6, {css:{display:"block", opacity:1}, ease:Power1.easeInOut});

	}
	public onClick(): void
	{
		if(this.isOpen)
		{
			this.close();
		}
		else
		{
			this.open();
		}
	}
	public open(): void
	{
		this.isOpen = true;
		
		gsap.to(this.menu, 0.3, {css:{display:"block", height:DataStorage.window_w/750*400}, ease:Power1.easeInOut});
		gsap.to(this.logo, 0.3, {css:{fill:"#ffffff"}, ease:Power1.easeOut});

		gsap.to(this.l0, 0.3, {css:{marginTop:0, rotation:30, backgroundColor:"#ffffff"}, ease:Power1.easeOut});
		gsap.to(this.l1, 0.3, {css:{marginTop:0, rotation:-30, backgroundColor:"#ffffff"}, ease:Power1.easeOut});

	}
	public close(): void
	{
		this.isOpen = false;

		gsap.to(this.menu, 0.3, {css:{display:"none", height:0}, ease:Power1.easeInOut});
		gsap.to(this.logo, 0.3, {css:{fill:"#585858"}, ease:Power1.easeOut});

		gsap.to(this.l0, 0.3, {css:{marginTop:DataStorage.window_w/750*6, rotation:0, backgroundColor:"#585858"}, ease:Power1.easeOut});
		gsap.to(this.l1, 0.3, {css:{marginTop:-DataStorage.window_w/750*6, rotation:0, backgroundColor:"#585858"}, ease:Power1.easeOut});
		
	}
	public remove(): void
	{
		gsap.set(this.element, {css:{display:"none"}});

	}
}