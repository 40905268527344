export class DataStorage
{
	public static LIFF_ID: string;

	//ステージング
	public static API_PATH: string = "https://d2wc6glaol856n.cloudfront.net/";
	public static INDEX_URL: string = "https://liff.line.me/1656936137-3126nXBa";
	public static MESSAGE_URL: string = "https://liff.line.me/1656936137-1aElbx4o";
	public static ENQUETE_URL: string = "https://liff.line.me/1656936137-woNEvnx5";

	//本番
	// public static API_PATH: string = "xxxxxxxxxxxx";
	// public static INDEX_URL: string = "xxxxxxxxxxxx";
	// public static MESSAGE_URL: string = "xxxxxxxxxxxx";
	// public static ENQUETE_URL: string = "xxxxxxxxxxxx";
	
	public static init_w: number;
	public static init_h: number;
	public static window_w: number;							//ウィンドウ幅
	public static window_h: number;							//ウィンドウ高さ
	public static scrollTop: number = 0;
	public static scrollLeft: number = 0;

	public static isSP: boolean;							//スマホかどうか
	public static isDebug: boolean = true;
	public static hasEpisode: boolean = false;
	public static isBookOpen: boolean = false;

	public static pageNum: number = 0;
	public static pageNumMax: number = 0;

	public static accessToken: string = "";
	public static episodeId: number;
	public static episodeNo: number;
	public static episodeText: string = "";

	// API
	public static episode: any;
	public static yourhistoryEpisode: any;
	public static usersstoryEpisode: any;
	
}