import { Event } from "../events/EventDispatcher";
import { SceneEvent } from "../SceneEvent";
import { DataStorage } from "../DataStorage";

export class APIController
{
	public static getEpisode(): void
	{
		// const _log:any = document.querySelector("._log")!;

		var xhr = new XMLHttpRequest();

		if(!DataStorage.isDebug)
		{
			xhr.open("GET", DataStorage.API_PATH+"/data/json/episode.json");
		}
		else
		{
			xhr.open("GET", "/data/getEpisode.json");
		}
		xhr.setRequestHeader("Content-Type", "application/json");
		xhr.send();

		xhr.addEventListener("load", function()
		{
			// _log.innerText += "\n【API:getEpisode___complete】-------";
			if (this.status!=200)
			{
				// _log.innerText += "\n "+this.status;
			}
			else
			{
				DataStorage.episode = JSON.parse(this.response);

				// const data:any = JSON.parse(this.response);
				// _log.innerText += "\n "+JSON.stringify(data);
			}
			// _log.innerText += "\n------------------------";
			
			SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.GET_EPISODE_COMPLETE));
		}, false);
		xhr.addEventListener("error", function(event)
		{
			// _log.innerText += "\n【API:getEpisode___error】-------";
			// _log.innerText += "\n "+event.type;
			// _log.innerText += "\n------------------------";
			
			SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.GET_EPISODE_ERROR));
		}, false);
	}
	public static postEpisodeYourhistoryEpisode(): void
	{
		// const _log:any = document.querySelector("._log")!;

		var xhr = new XMLHttpRequest();
		let send_string =
		{
			"accesstoken": DataStorage.accessToken
		};

		if(!DataStorage.isDebug)
		{
			xhr.open("POST", DataStorage.API_PATH+"api/v1/episode/yourhistory");
		}
		else
		{
			xhr.open("GET", "/data/postEpisodeYourhistoryEpisode.json");
		}
		xhr.setRequestHeader("Content-Type", "application/json");
		xhr.send(JSON.stringify(send_string));

		xhr.addEventListener("load", function()
		{
			// _log.innerText += "\n【API:postEpisodeYourhistoryEpisode___complete】-------"+this.status;
			if (this.status!=200)
			{
				// _log.innerText += "\n "+this.status;
			}
			else
			{
				DataStorage.yourhistoryEpisode = JSON.parse(this.response);
				// DataStorage.yourhistoryEpisode = [];

				// const data:any = JSON.parse(this.response);
				// _log.innerText += "\n "+JSON.stringify(data);
			}
			// _log.innerText += "\n------------------------";
			
			SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.POST_EPISODE_YOURHISTORY_EPISODE_COMPLETE));
		}, false);
		xhr.addEventListener("error", function(event)
		{
			// _log.innerText += "\n【API:postEpisodeYourhistoryEpisode___error】-------";
			// _log.innerText += "\n "+event.type;
			// _log.innerText += "\n------------------------";
			
			SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.POST_EPISODE_YOURHISTORY_EPISODE_ERROR));
		}, false);
	}
	public static getEpisodeByEpisodeId(episodeId:number): void
	{
		// const _log:any = document.querySelector("._log")!;

		DataStorage.hasEpisode = false;

		var xhr = new XMLHttpRequest();

		if(!DataStorage.isDebug)
		{
			xhr.open("GET", DataStorage.API_PATH+"api/v1/cashe/episode/"+episodeId);
		}
		else
		{
			xhr.open("GET", "/data/getEpisodeByEpisodeId.json");
		}
		xhr.setRequestHeader("Content-Type", "application/json");
		xhr.send();

		xhr.addEventListener("load", function()
		{
			// _log.innerText += "\n【API:getEpisodeByEpisodeId___complete】-------:"+episodeId;
			if (this.status!=200)
			{
				// _log.innerText += "\n "+this.status;
			}
			else
			{
				DataStorage.usersstoryEpisode = JSON.parse(this.response);

				// const data:any = JSON.parse(this.response);
				// _log.innerText += "\n "+JSON.stringify(data);
			}
			// _log.innerText += "\n------------------------";

			DataStorage.hasEpisode = true;
			
			SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.GET_EPISODE_BY_EPISODE_ID_COMPLETE));
		}, false);
		xhr.addEventListener("error", function(event)
		{
			// _log.innerText += "\n【API:getEpisodeByEpisodeId___error】-------";
			// _log.innerText += "\n "+event.type;
			// _log.innerText += "\n------------------------";
			
			SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.GET_EPISODE_BY_EPISODE_ID_ERROR));
		}, false);
	}
	public static postEpisodeSend(episode:string): void
	{
		// const _log:any = document.querySelector("._log")!;

		var xhr = new XMLHttpRequest();
		let send_string =
		{
			"accesstoken": DataStorage.accessToken,
			"episode": episode
		};

		xhr.open("POST", DataStorage.API_PATH+"api/v1/episode/send");
		xhr.setRequestHeader("Content-Type", "application/json");
		xhr.send(JSON.stringify(send_string));

		xhr.addEventListener("load", function()
		{
			// _log.innerText += "\n【API:postEpisodeSend___complete】-------"+this.status;
			if (this.status!=200)
			{
				SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.POST_EPISODE_SEND_ERROR));
			}
			else
			{
				SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.POST_EPISODE_SEND_COMPLETE));
			}
			// _log.innerText += "\n------------------------";
			
		}, false);
		xhr.addEventListener("error", function(event)
		{
			// _log.innerText += "\n【API:postEpisodeSend___error】-------";
			// _log.innerText += "\n "+event.type;
			// _log.innerText += "\n------------------------";
			
			SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.POST_EPISODE_SEND_ERROR));
		}, false);
	}
}
