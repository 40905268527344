import { Event } from "../events/EventDispatcher";
import { SceneEvent } from "../SceneEvent";
import { APIController } from '../controller/APIController';

export class SceneController
{
	private getEpisodeBindThis: Function;
	private postEpisodeYourhistoryEpisodeBindThis: Function;
	private setTopBindThis: Function;

	constructor()
	{
		this.getEpisodeBindThis = this.getEpisode.bind(this);
		SceneEvent.getInstance().addEventListener(SceneEvent.LIFF_INIT_COMPLETE, this.getEpisodeBindThis);

		this.postEpisodeYourhistoryEpisodeBindThis = this.postEpisodeYourhistoryEpisode.bind(this);
		SceneEvent.getInstance().addEventListener(SceneEvent.GET_EPISODE_COMPLETE, this.postEpisodeYourhistoryEpisodeBindThis);

		this.setTopBindThis = this.setTop.bind(this);
		SceneEvent.getInstance().addEventListener(SceneEvent.POST_EPISODE_YOURHISTORY_EPISODE_COMPLETE, this.setTopBindThis);
		SceneEvent.getInstance().addEventListener(SceneEvent.POST_EPISODE_YOURHISTORY_EPISODE_ERROR, this.setTopBindThis);

	}
	public getEpisode(): void
	{
		APIController.getEpisode();
		
	}
	public postEpisodeYourhistoryEpisode(): void
	{
		APIController.postEpisodeYourhistoryEpisode();
		
	}
	public setTop(): void
	{
		SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.ADD_OPENING));
		SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.ADD_MESSAGE_INPUT));
		
	}
}
