import { EventDispatcher } from "./events/EventDispatcher";

export class SceneEvent extends EventDispatcher
{
	public static WINDOW_RESIZE: string = "WINDOW_RESIZE";
    public static WINDOW_SCROLL = "WINDOW_SCROLL";

    public static LIFF_INIT_COMPLETE = "LIFF_INIT_COMPLETE";

    public static ADD_HEADER = "ADD_HEADER";
    public static REMOVE_HEADER = "REMOVE_HEADER";

    public static ADD_OPENING = "ADD_OPENING";
    public static REMOVE_OPENING = "REMOVE_OPENING";

    public static ADD_TOP = "ADD_TOP";
    public static REMOVE_TOP = "REMOVE_TOP";
    public static BACK_TO_TOP = "BACK_TO_TOP";

    public static ADD_BOOK = "ADD_BOOK";
    public static REMOVE_BOOK = "REMOVE_BOOK";
    public static BOOK_OPEN_COMPLETE = "BOOK_OPEN_COMPLETE";
    public static BOOK_NEXT_COMPLETE = "BOOK_NEXT_COMPLETE";
    public static BOOK_PREV_COMPLETE = "BOOK_PREV_COMPLETE";
    public static BOOK_CLOSE = "BOOK_CLOSE";
    public static BOOK_CLOSE_COMPLETE = "BOOK_CLOSE_COMPLETE";

    public static PAGE_NEXT = "PAGE_NEXT";
    public static PAGE_PREV = "PAGE_PREV";
	
    public static ADD_EPISODE = "ADD_EPISODE";
    public static REMOVE_EPISODE = "REMOVE_EPISODE";
	
    public static ADD_SEND_YOURHISTORY = "ADD_SEND_YOURHISTORY";
    public static REMOVE_SEND_YOURHISTORY = "REMOVE_SEND_YOURHISTORY";

    public static ADD_MESSAGE_INPUT = "ADD_MESSAGE_INPUT";
    public static REMOVE_MESSAGE_INPUT = "REMOVE_MESSAGE_INPUT";

    public static ADD_MESSAGE_CHECK = "ADD_MESSAGE_CHECK";
    public static REMOVE_MESSAGE_CHECK = "REMOVE_MESSAGE_CHECK";

    public static ADD_MESSAGE_THANKS = "ADD_MESSAGE_THANKS";
    public static REMOVE_MESSAGE_THANKS = "REMOVE_MESSAGE_THANKS";
	
    public static ADD_SEND_YOUR_HISTORY_MODAL = "ADD_SEND_YOUR_HISTORY_MODAL";
    public static REMOVE_SEND_YOUR_HISTORY_MODAL = "REMOVE_SEND_YOUR_HISTORY_MODAL";

	// API
    public static GET_EPISODE_COMPLETE = "GET_EPISODE_COMPLETE";
    public static GET_EPISODE_ERROR = "GET_EPISODE_ERROR";
    public static POST_EPISODE_YOURHISTORY_EPISODE_COMPLETE = "POST_EPISODE_YOURHISTORY_EPISODE_COMPLETE";
    public static POST_EPISODE_YOURHISTORY_EPISODE_ERROR = "POST_EPISODE_YOURHISTORY_EPISODE_ERROR";
    public static GET_EPISODE_BY_EPISODE_ID_COMPLETE = "GET_EPISODE_BY_EPISODE_ID_COMPLETE";
    public static GET_EPISODE_BY_EPISODE_ID_ERROR = "GET_EPISODE_BY_EPISODE_ID_ERROR";
    public static POST_EPISODE_SEND_COMPLETE = "POST_EPISODE_SEND_COMPLETE";
    public static POST_EPISODE_SEND_ERROR = "POST_EPISODE_SEND_ERROR";

	private static _instance: SceneEvent;

	public static getInstance(): SceneEvent
	{
		if(!this._instance)
		{
			this._instance = new SceneEvent(SceneEvent.getInstance);
		}

		return this._instance;
	}

	constructor(caller: Function)
	{
		if(caller == SceneEvent.getInstance)
		{
			super();
		}
		else if(SceneEvent._instance)
		{
			throw new Error("既にインスタンスが存在するためエラー。");
		}
		else
		{
			throw new Error("コンストラクタの引数が不正な為エラー。");
		}
	}
}